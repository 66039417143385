<template>
  <div class="finance-list">
    <CommonList
      ref="commonList"
      bottomBtnText="提交"
      :showTop="false"
      :cellOperabled="false"
      :showBottom="true"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import CommonList from "@components/CommonList.vue";

export default {
  name: "Finance",

  components: {
    CommonList,
  },

  data() {
    return {};
  },
  methods: {
    onSubmit(data) {
      if (data.length == 0) {
        this.$toast("请先勾选票据");
        return;
      }
      this.$router.push({
        name: "submitFinance",
        query: {
          bills: JSON.stringify(data),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
